import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faBullhorn, faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import logo from '../img/photo.png'; // Adjust the path to your image

const Hero = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-700"></div>
      </div>
    );
  }

  return (
    <section className="container mx-auto p-6 pt-32 flex flex-col md:flex-row items-center select-none">
      <div className="w-full md:w-1/2 space-y-6 mb-6 md:mb-0">
        <h1 className="text-3xl md:text-5xl font-bold">Let's Work Together to Create Wonders with Us</h1>
        <p className="text-gray-700">A highly skilled Full Stack Developer and Digital Marketing Specialist with over 7 years of experience in building robust web applications and successful marketing strategies.</p>
        <div className="space-x-4">
          <button className="bg-purple-700 text-white px-6 py-3 font-semibold rounded hover:bg-purple-900">Let's Talk</button>
          <button className="bg-gray-200 text-gray-700 px-6 py-3 font-semibold rounded hover:bg-gray-300">Start Project</button>
        </div>
        <div className="flex space-x-6 mt-6">
          <div>
            <p className="text-2xl font-bold">7+</p>
            <p className="text-gray-700">years experience</p>
          </div>
          <div>
            <p className="text-2xl font-bold">26</p>
            <p className="text-gray-700">projects success</p>
          </div>
          <div>
            <p className="text-2xl font-bold">98%</p>
            <p className="text-gray-700">satisfied rate</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 relative bg-purple-50 rounded-full">
        <img src={logo} alt="Hero" className="w-full h-full object-cover rounded-full" />
        <div className="absolute top-0 right-0 mt-4 mr-4 flex-col space-y-4 hidden md:flex">
          <div className="flex items-center bg-white p-4 rounded shadow-lg">
            <FontAwesomeIcon icon={faCode} className="w-8 h-8" />
            <p className="ml-2">Web Development</p>
          </div>
          <div className="flex items-center bg-white p-4 rounded shadow-lg">
            <FontAwesomeIcon icon={faBullhorn} className="w-8 h-8" />
            <p className="ml-2">Digital Marketing</p>
          </div>
          <div className="flex items-center bg-white p-4 rounded shadow-lg">
            <FontAwesomeIcon icon={faPaintBrush} className="w-8 h-8" />
            <p className="ml-2">Creative Branding</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
