import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCogs, faBriefcase, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  useEffect(() => {
    const menuBtn = document.getElementById('menu-btn');
    const mobileMenu = document.getElementById('mobile-menu');
    const links = document.querySelectorAll('#mobile-menu a');

    const toggleMenu = () => {
      mobileMenu.classList.toggle('hidden');
    };

    const closeMenu = () => {
      mobileMenu.classList.add('hidden');
    };

    if (menuBtn) {
      menuBtn.addEventListener('click', toggleMenu);
    }

    links.forEach(link => {
      link.addEventListener('click', closeMenu);
    });

    return () => {
      if (menuBtn) {
        menuBtn.removeEventListener('click', toggleMenu);
      }

      links.forEach(link => {
        link.removeEventListener('click', closeMenu);
      });
    };
  }, []);

  return (
    <header className="container mx-auto p-6 fixed top-0 left-0 right-0 bg-white shadow-lg rounded-lg z-50 navbar select-none">
      <nav className="flex justify-between items-center">
        <div className="text-2xl font-bold heartbeat">AmrDev.</div>
        <ul className="hidden md:flex space-x-6">
          <li>
            <Link to="/" className="hover:duration-300 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />Resume
            </Link>
          </li>
          <li>
            <Link to="/Service" className="hover:duration-300 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              <FontAwesomeIcon icon={faCogs} className="mr-2" />Service & Order
            </Link>
          </li>
          <li>
            <Link to="/Portfolio" className="hover:duration-300 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" />Portfolio
            </Link>
          </li>
        </ul>
        <Link to="/Contact" className="transition duration-700 bg-purple-700 font-medium text-white px-4 py-2 rounded hidden md:block hover:bg-purple-900">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />Get a Touch
        </Link>
        <button id="menu-btn" className="md:hidden text-gray-700 focus:outline-none" aria-label="Open menu">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </nav>
      <div id="mobile-menu" className="mobile-menu hidden md:hidden mt-4">
        <ul className="space-y-4">
          <li>
            <Link to="/" className="hover:duration-300 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />Resume
            </Link>
          </li>
          <li>
            <Link to="/Service" className="hover:duration-300 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              <FontAwesomeIcon icon={faCogs} className="mr-2" />Service & Order
            </Link>
          </li>
          <li>
            <Link to="/Portfolio" className="hover:duration-300 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-700 hover:text-white">
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" />Portfolio
            </Link>
          </li>
          <li>
            <Link to="/Contact" className="transition duration-700 bg-purple-700 text-white px-4 py-2 rounded block text-center hover:bg-purple-900">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />Get a Touch
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
