import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faClock } from '@fortawesome/free-solid-svg-icons';
import logo from '../img/photo.png'; // Adjust the path to your image

const Maintenance = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }
  return (
    <section className="container mx-auto p-6 pt-32 flex flex-col md:flex-row items-center">
      <div className="w-full md:w-1/2 space-y-6 mb-6 md:mb-0">
        <h1 className="text-3xl md:text-5xl font-bold">We are currently under maintenance</h1>
        <p className="text-gray-700">Our website is undergoing scheduled maintenance to improve your experience. We apologize for any inconvenience and appreciate your patience. Please check back later.</p>
        <div className="space-x-4">
          <button className="bg-gray-200 text-gray-700 px-6 py-3 rounded hover:bg-gray-300" disabled>Contact Support</button>
          <button className="bg-gray-200 text-gray-700 px-6 py-3 rounded hover:bg-gray-300" disabled>Maintenance Updates</button>
        </div>
        <div className="flex space-x-6 mt-6">
          <div>
            <p className="text-2xl font-bold"><FontAwesomeIcon icon={faClock} /></p>
            <p className="text-gray-700">Expected Downtime</p>
          </div>
          <div>
            <p className="text-2xl font-bold"><FontAwesomeIcon icon={faTools} /></p>
            <p className="text-gray-700">Maintenance in Progress</p>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 relative bg-gray-100 rounded-full">
        <img src={logo} alt="Maintenance" className="w-full h-full object-cover rounded-full" />
        <div className="absolute top-0 right-0 mt-4 mr-4 flex-col space-y-4 hidden md:flex">
          <div className="flex items-center bg-white p-4 rounded shadow-lg">
            <FontAwesomeIcon icon={faTools} className="w-8 h-8" />
            <p className="ml-2">Scheduled Maintenance</p>
          </div>
          <div className="flex items-center bg-white p-4 rounded shadow-lg">
            <FontAwesomeIcon icon={faClock} className="w-8 h-8" />
            <p className="ml-2">Please Check Back Soon</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Maintenance;
